body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
}

.main-layout {
  display: flex;
  height: calc(100vh - 60px); /* Adjust for the height of the Header */
  margin-top: 60px; /* Push content below the header */
  overflow: hidden; /* Prevent content from overflowing */
}

.sidebar {
  width: 250px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 20px;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  position: relative; /* Ensure content respects the footer */
}

button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
